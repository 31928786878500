<template>
    <main>
        <div class="demo-wrapper">
            <Button class="demo-button p-button-raised" @click="$router.push('/demo/streaming')">Streaming</Button>
            <Button class="demo-button p-button-raised" @click="$router.push('/demo/vod')">VOD</Button>
            <Button class="demo-button p-button-raised" @click="$router.push('/demo/bidirectional')">Bidirectionality</Button>
            <Button class="demo-button p-button-raised" @click="$router.push('/demo/test')">Test</Button>
        </div>
    </main>
</template>

<style scoped>
.demo-wrapper {
    display: grid;
    justify-content: center;
    align-content: center;
    gap: 5em;
}

.demo-button {
    width: 20em;
    height: 5em;
    justify-content: center;
    font-size: 2em;
}
</style>
